/*@import url("https://fonts.googleapis.com/css?family=Press+Start+2P");*/
/*@import url("https://fonts.googleapis.com/css?family=VT323");*/
@import url("https://fonts.googleapis.com/css?family=Spartan");
@import url('http://fonts.cdnfonts.com/css/chunky-rosie');

.App {
    text-align: center;
    font-family: "Spartan", "sans-serif";
    color: white;
}

.logo {
    background: url(https://www.andyhooke.co.uk/wp-content/uploads/2018/02/yellow-brushstroke.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding: 8px 0;
}

* {
    box-sizing: border-box;
}

body {
    overflow: hidden;
}

.overlay {
    opacity: 0.85;
    width: 100%;
    height: 100%;
    z-index: 10;
    top: 0;
    left: 0;
    position: fixed;
}

.moving-background {
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url("./assets/background/wildwarz-whitelist-bg.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: 40% 40%;
}

.gradient{
    background: linear-gradient(
            90deg,
            rgba(255, 255, 255, 0) -1.52%,
            rgba(255, 255, 255, 0.024) 104.35%
    ),
    rgba(162, 96, 243, 0.3);
}
